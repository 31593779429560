.question-text {
  font-weight: bold;
}

.answer-text {
  padding: 4px 10px;
  font-size: 20px;
}

.info-container {
  float: left;
  border: 1px dotted #36c;
  width: 100%;
  padding: 10px;
}

.info-item-container {
  margin-top: 4px;
}

.info-item-label {
  font-weight: bold;
}

.info-item-date-container {
  float: right;
}

.qa-container {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.qa-line {
  border: 1px dotted #36c;
}

.product-serial-line {
  border: 2px solid #45d464;
  border-color: var(--color-secondary);
}

CardText {
  color: red;
  font-size: 150px;
}

.dialogCloseButton {
  margin-top: 2%;
  margin-left: 93%;
  position: fixed;
}

.dialog {
  padding: 30px;
}

.inspection-container {
  padding: 50px;
}

@media print {
  .inspection-container, .dialog {
    margin: 0;
    padding: 0;
  }

  .answer-text {
    font-size: 12px;
  }

  .qa-container {
    margin: 0;
    padding: 0;
  }

  .dialogCloseButton {
    visibility: hidden;
  }
}
/*# sourceMappingURL=workspace.b8e614f7.css.map */
